import React from 'react';
import personal from "../assets/Personal-Finance-Debt-Relief.svg"
import { Accordain } from './Accordain';

const Faqs = () => {
  return (
    <div>
      <div className="row justify-content-center" >
        <h1 className="textdark font-30"> FAQs</h1>
        <div className="col-10 mt-4">
          <div className="" >
            <Accordain />
          </div>
          <div className="faqs p-5 py-5 mt-5">
            <img src={personal} alt="" />
            <h1 className="font-40 textdark fw-600 mt-5 mb-3"> Still have questions?</h1>
            {/* <button type="button" className="callus mt-4"> 800-300-9550 </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs
