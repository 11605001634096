import React, { useState } from "react";
const Step2 = ({ validationError, firstName, getFirstName, lastName, getLastName, getPhoneNumber, getEmail, email }) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  // Function to format phone number with brackets
  const formatPhoneNumber = (input) => {
    const numericValue = input.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters and limit to 10 digits

    if (numericValue.length < 4) {
      return numericValue;
    } else if (numericValue.length < 7) {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
    }
  };


  // Event handler for input changes
  const handlePhoneNumberChange = (event) => {
    const currentValue = event.target.value;
    setPhoneNumber(formatPhoneNumber(currentValue));
    getPhoneNumber(formatPhoneNumber(currentValue));
  };


  return (
    <>
      <div className="row justify-content-center mt-5 p-3">
        <div className="col-lg-10  col-12">
          <h1 className="textdark font-42">
            Take The Next Steps Toward <br /> Financial Stability
          </h1>
        </div>
        <div className="col-md-5 col-12 mt-5">
          <div className="row">
            <div className="col-md-12">
              <div class="input-container">
                <input
                  value={firstName}
                  onChange={(e) => getFirstName(e)}
                  style={{ border: validationError && firstName == '' && "1px solid red" }}
                  type="text"
                  name="firstName"
                  id="firstName"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && firstName == null ? 'red-border' : ''}`}
                  placeholder="Enter First Name"
                  defaultValue=''
                />
                <label className='pdr-label' for="address">First Name</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="input-container">
                <input
                  value={lastName}
                  onChange={(e) => getLastName(e)}
                  type="text"
                  name="lastName"
                  id="lastName"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && lastName == null ? 'red-border' : ''}`}
                  placeholder="Enter Last Name"
                  defaultValue=''
                />
                <label className='pdr-label' for="address">Last Name</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="input-container">
                <input
                  value={email}
                  onChange={(e) => getEmail(e)}
                  type="email"
                  name="email"
                  id="email"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && email == null ? 'red-border' : ''}`}
                  placeholder="Enter Email"
                  defaultValue=''
                />
                <label className='pdr-label' for="address">Email</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="input-container">
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && phoneNumber == null ? 'red-border' : ''}`}
                  placeholder="Enter Phone"
                  defaultValue=''
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
                <label className='pdr-label' for="address">Phone</label>
              </div>
            </div>
          </div>
          {validationError && (
            <div className="text-center mt-3" style={{ color: "red" }}>
              {validationError}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Step2;
