import React from "react";
const Step6 = ({
  getEmploymentStatus
}) => {
  return (
    <>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 col-md-8">
          <h1 > What`s your current empolyment status? </h1>
          <p className="font-18 textccc"> Your free consultation and personalized plan are as close as your phone.</p>
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div class="d-flex justify-content-center" style={{padding:'30px'}}>
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button onClick={()=>getEmploymentStatus('Employed')} class="nav-link active fw-bold" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Employed</button>
              <button onClick={()=>getEmploymentStatus('Self-Employed')} class="nav-link mt-3 fw-bold" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Self-Employed</button>
              <button onClick={()=>getEmploymentStatus('Student')}  class="nav-link mt-3 fw-bold" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false">Student</button>
              <button onClick={()=>getEmploymentStatus('Retired')} class="nav-link mt-3 fw-bold" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Retired</button>
              <button onClick={()=>getEmploymentStatus('Unemployed')} class="nav-link mt-3 fw-bold" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Unemployed</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step6;
