import React from "react";
const Step7 = ({
  getPhoneNumber,
  validationError,
  phoneNumber,
  email,
  getEmail,
  firstName,
  lastName
}) => {
  return (
    <>
      <div className="row justify-content-center">
       <div className="col-12">
        <div className="row justify-content-center">
          <div className="col-10">
          <div>
            <h1 className="textdark font-42"> Let's Talk, {firstName+" "+lastName}</h1>
            <p className="font-18 textccc"> Your free consultation and personalized plan are as close as your phone.</p>
          </div>
          <div className="alertcard">
            <h3 className="textgreen font-30 ">
            A debt specialist will be calling you shortly from our toll-free number.
            </h3>
          </div > 
          <p className="textccc font-18 mt-2">You can also call us now at:</p>
          </div>
        </div>
      
       </div>
      </div>
    </>
  );
};

export default Step7;
