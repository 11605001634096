import React from 'react';
import { Link } from 'react-router-dom';

const ToDay = (props) => {
  return (
    <>
      {/*section- Contractor today*/}
      <div className="container-fluid pb-5 pt-5 bg-img1">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6 col-12 align-self-center">
              <h3 className="font_42 font_700 font-Bold-Ivy green_color">
                Try Contractor today{" "}
                <span className="text-black">
                  and get
                  <br /> funding for your business
                </span>
              </h3>
              <Link to="/">
                <button type="button" onClick={props.scrollToTop} className="btn-Pill  mt-3">
                  Let’s Get Started
                </button>
              </Link>

            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default ToDay;
