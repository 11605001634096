import React from 'react'

import line from '../assets/img13.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer'
import ToDay from '../components/ToDay'
import Customer from '../components/Customer'
import { useEffect } from 'react'

const About = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
          });
      }, [])
    const settings = {
        dots: false,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 1500,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
           {/* section--about */}
            <div className="conatiner-fluid bg-img  backgroundcolor position-relative pb-5">
                <img src={line} className="position-absolute inner_imges" alt="" />
             
               
                <div className="container pt-1">
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-10 col-md-6 col-12 ">
                            <h3 className='font_32 font_700 font-Bold-Ivy '>Here’s what <span className='coloryellow'>Contractor</span> <br /> Does for you</h3>
                            <p className="font_20 font-DM-Sans color_gray">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam ut non nulla hic in iusto error? Reprehenderit consequatur sint magni. Ad, esse asperiores temporibus optio distinctio nisi perferendis laborum illum molestias adipisci, blanditiis cum, saepe minus tempora! Ab, voluptate laboriosam ad facere mollitia exercitationem voluptatem quasi rerum quae necessitatibus totam cum praesentium. Dicta magnam aspernatur, nisi, ut distinctio debitis veritatis dignissimos sunt doloribus dolorum totam ducimus illum exercitationem nihil quidem. Laborum officiis soluta, iure mollitia quia amet voluptatem quis aliquam quasi expedita dolor rerum ex, quod dolore deserunt, tempora totam tenetur. Porro esse necessitatibus quod iusto sunt beatae fugit. Repellendus  
                            </p>
                        </div>
                       
                    </div>
                </div>
            </div>
          
            {/* Who we are? */}
            <section className='second-Section'>
                <div className="container colorwhite pt-5">
                    <div className="row justify-content-center">
                     
                        <div className="col-lg-10  col-md-10 col-12">
                            <h3 className='font_32 font_700 font-Bold-Ivy '>Who we are?</h3>
                            <p className="font_18 font-DM-Sans color_gray">At Contractor, we’re committed to supporting small businesses with responsible financing solutions and world-class service.
                                We believe that small businesses are the backbone of the economy, and we’re dedicated to helping them thrive. We accomplish this by providing loans using cutting-edge technology, making the process as simple and streamlined as possible.
                                <p>

                                    We’re also transparent with our pricing and terms, so our customers can make informed decisions.
                                    And finally, we want to provide the best customer experience possible. That means providing excellence in all aspects of the customer’s Qualifier experience. We’re proud to stand behind our small business customers and help them grow and succeed.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <br /><br />
            {/* Fuhnd Your Business */}
            <div className="container mt-5 pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-6 col-12">
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Grow Your Business</h3>
                        <p className="font_18 font-DM-Sans">At Contractor, we’re committed to supporting small businesses with responsible financing solutions and world-class service.
                            We believe that small businesses are the backbone of the economy, and we’re dedicated to helping them thrive. We accomplish this by providing loans using cutting-edge technology, making the process as simple and streamlined as possible.
                            <p>

                                We’re also transparent with our pricing and terms, so our customers can make informed decisions.
                                And finally, we want to provide the best customer experience possible. That means providing excellence in all aspects of the customer’s Qualifier experience. We’re proud to stand behind our small business customers and help them grow and succeed.
                            </p>
                        </p>
                    </div>
                    

                </div>
            </div>
            <br /><br />
            <Customer />
            {/*--section-about-1stslider--*/}
           
            <ToDay />
            <div className="container-fluid">
            
            <Footer />
            </div>
        </>
    )
}

export default About
