import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Step9 = () => {
    return (
        <div className="row justify-content-center">
            <div className="col-lg-8 col-12 mb-5">
                <h3 className="mt-4 mb-0 font-DM-Sans question">What kind of debts make up your total debt?
                </h3>
                <p>Check all that apply:
                </p>
                <div className='checkbox-container'>
                    <FormControlLabel control={<Checkbox />} label="Credit Cards
" />
                    <FormControlLabel control={<Checkbox />} label="Store card debt" />
                    <FormControlLabel control={<Checkbox />} label="Personal Loans
" />
                    <FormControlLabel control={<Checkbox />} label="Personal Lines of Credit
" />
                    <FormControlLabel control={<Checkbox />} label="Medical Bills
" />
                    <FormControlLabel control={<Checkbox />} label="Collections
" />
                    <FormControlLabel control={<Checkbox />} label="Collections
" />
                    <FormControlLabel control={<Checkbox />} label="Other" />

                </div>
            </div>
        </div>
    )
}

export default Step9
