import React, { useState } from 'react';

import line from '../assets/img13.svg'
import img from '../assets/pic13.svg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ButtonLoader from './ButtonLoader';
const ApplicationStep2 = () => {
    const navigate = useNavigate()
    // ---------GET--DATA---IN---LOCALSTORAGE------
    const storeApplicationTwo = localStorage.getItem('applicationTwo');
    const parsedstoreApplicationTwo = storeApplicationTwo ? JSON.parse(storeApplicationTwo) : null;
    const stepDbaName = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.dba_name : '';
    const stepfederal = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.federal_tax_id : '';
    const stepStartDate = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.business_start_date : '';
    const stepStructure = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.structure : '';
    const stepAddress = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.address : '';
    const stepCity = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.city : '';
    const stepState = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.state : '';
    const stepZip = parsedstoreApplicationTwo ? parsedstoreApplicationTwo.zip : '';
    const [dba_name, setDba_name] = useState(stepDbaName);
    const [business_name, setBusinessName] = useState('');

    const [federal, setFederal] = useState(stepfederal);
    const [business_start_date, setBusiness_start_date] = useState(stepStartDate);
    const [structure, setStructure] = useState(stepStructure);
    const [address, setAddress] = useState(stepAddress);
    const [city, setCity] = useState(stepCity);
    const [state, setState] = useState(stepState);
    const [zip, setZip] = useState(stepZip);

    const [validationError, setValidationError] = useState(null);
    const stepsdata = JSON.parse(localStorage.getItem('stepFourData'))
    const Stepsid = JSON.parse(localStorage.getItem('id'))
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if ((dba_name.length > 0) && (federal.length > 0) && (business_start_date != '') && (structure != '') &&
            (address.length > 0) && (city.length > 0) && (state.length > 0) && (zip.length > 0)) {

            let data = {
                "id": Stepsid,
                "dba_name": dba_name,
                "business_name": business_name,
                "federal_tax_id": federal,
                "business_start_date": business_start_date,
                "structure": structure,
                "address": address,
                "city": city,
                "state": state,
                "zip": zip,
            };
            try {
                const response = await axios.post('https://lohnz.codingsolution24.com/api/create-step-one', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                console.log(JSON.stringify(response.data));
                localStorage.setItem('applicationTwo', JSON.stringify(response.data.data));

                // setStepId(response.data.data.id);
                navigate('/application3');


            } catch (error) {
                console.log(error);
            }
            setValidationError(null)
        }
        else {
            setValidationError(<div className="alert alert-danger text-start" role="alert">
                Please complete the all detail
            </div>)
        }

    }
    return (
        <>
            <div
                className="container-fluid p-0 position-relative"
                style={{ backgroundColor: "#EFF2ED" }}
            >
                <img src={line} className="position-absolute iner_img" alt="" />
                {/* -------Navbar----------- */}
                <div
                    className="container-fluid bg_color position-relative"
                    style={{ zIndex: 3 }}
                >
                  
                </div>
                {/* ---------main-sec-1----- */}
                <div className="container pt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-md-8 col-12 mt-3">

                            <p className="btn_empty font_15 previous cursor-pointer" onClick={() => navigate(-1)}>
                                <span>
                                    <i className="fa-solid fa-arrow-left  me-2" />
                                </span>{" "}
                                Back
                            </p>

                            <br className='d-lg-block d-md-block d-none' />
                            <br className='d-lg-block d-md-block d-none' />
                            <div className="tab">
                                <ul className="d-block nav nav-pills">
                                    <div className="nav-item font_15 mt-5 d-flex">
                                        <div>
                                            <img src={img} alt="" />
                                        </div>
                                        <div className="flex-column d-flex justify-content-between ms-4">
                                            <p className="mb-0">Business Information</p>
                                            <p className="mb-0">Owner information</p>
                                            <p className="mb-0">Capital information</p>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-10 col-12 application-fieled  mt-lg-0 mt-md-0  mt-4">
                            <h3 className="Satoshi_fonts mb-3 font_32 font_700 font-Bold-Ivy text-center">
                                Tell us about your business
                            </h3>
                            <p
                                style={{ color: "#525252" }}
                                className="DM_fonts font_15 text-center"
                            >
                                Your business complete information will help us to make your process
                                fast
                            </p>

                            <form className="row DM_fonts" onSubmit={handleSubmit}>
                                <div className="col-lg-6 col-md-6 col-12 mt-4">
                                    <label style={{ color: "#525252" }}>Business Name</label>
                                    <input
                                        className="form-control font_15 Satoshi_fonts input_field font_15"
                                        type="text"
                                        value={business_name}
                                        style={{ fontWeight: 500 }}
                                        onChange={(e) => setBusinessName(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="dba">
                                        DBA Name
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="dba"
                                        value={dba_name}
                                        onChange={(e) => setDba_name(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="tax">
                                        Federal Tax ID
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="tax"
                                        onChange={(e) => setFederal(e.target.value)}
                                        value={federal} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-4">
                                    <label style={{ color: "#525252" }}>Business Start Date</label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type='date'
                                        style={{ fontWeight: 500 }}
                                        value={business_start_date}
                                        onChange={(e) => setBusiness_start_date(e.target.value)}
                                    >

                                    </input>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-4">
                                    <label style={{ color: "#525252" }}>Structure</label>
                                    <select
                                        className="form-select font_15 Satoshi_fonts input_field font_15"
                                        aria-label="Default select example form-control"
                                        style={{ fontWeight: 500 }}
                                        value={structure}
                                        onChange={(e) => setStructure(e.target.value)}
                                    >
                                        <option selected="">Select</option>
                                        <option value="Sole proprietorship" >Sole proprietorship
                                        </option>
                                        <option value="Partnership">Partnership</option>
                                        <option value="Corporation">Corporation</option>
                                        <option value="Sole proprietorship">Sole proprietorship</option>
                                        <option value="LLC">LLC</option>
                                        <option value="LLP">LLP</option>
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="add">
                                        Address
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="add"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="dba">
                                        City
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="dba"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-6 ">
                                            <label style={{ color: "#525252" }} htmlFor="state">
                                                State
                                            </label>
                                            <input
                                                className="font_15 form-control input_field"
                                                type="text"
                                                id="state"
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 ">
                                            <label style={{ color: "#525252" }} htmlFor="zip">
                                                ZIP
                                            </label>
                                            <input
                                                className=" font_15 form-control input_field"
                                                type="text"
                                                id="zip"
                                                value={zip}
                                                onChange={(e) => setZip(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {validationError &&
                                    <div className='text-center mt-3' style={{ color: 'red' }}>
                                        {validationError}
                                    </div>
                                }
                                <div className="text-center mt-4 ">

                                    <button className="btn-fill w-75 font_15 ms-1 next">
                                        {isLoading ? <ButtonLoader /> : 'Continue'}
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*footer*/}
                <footer className="container">
                    <p className="font_20 mt-lg-0  mt-4">
                        <i className="fa-solid fa-phone-volume me-2" />
                        Need Help?
                    </p>
                    <h3 className="font_22 font_700 font-DM-Sans">310-307-1916</h3>
                    <hr style={{ border: "1px solid #FFFFFF" }} />
                    <div className="text-center pb-2">
                        <p className="m-0">© Copyright 2023 | LOHNZ. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>


        </>
    );
}

export default ApplicationStep2;
