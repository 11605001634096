import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";

const Sixthsec = () => {
  return (
    <div>
      <div className="container p-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
              <div className="mb-2 mb-sm-0">
                <FaPhoneAlt /> <span>(888) 414-6765</span>
              </div>
              <div className="text-center text-sm-end">
                Pure Debt Relief
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sixthsec
