import React from "react";
import CustomSelect from "./CustomSelect";
import { FaCheck } from "react-icons/fa6";
const Step1 = ({ getDebtAmount, debtAmount, validationError }) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-12 Resp_16 mt-5 mb-3 p-3">
          <h1 className="page-title"> See If You Qualify For Debt Relief</h1>
          <div className="text-center mt-5 p-3 debt-checks" style={{lineHeight:'1.9'}}>
            <div className="d-flex align-items-center">
              <div>
                <FaCheck color="#0077E3" size={20} />
              </div>
              <div className="ms-3">
                <p className="textwhite mb-0">
                  Pay Up To 50% Lower Monthly Payments
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <FaCheck color="#0077E3" size={20} />
              </div>
              <div className="ms-3">
                <p className="textwhite mb-0">
                  {" "}
                  Debt Free In As Little As 24-48 Months
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <FaCheck color="#0077E3" size={20} />
              </div>
              <div className="ms-3">
                <p className="textwhite mb-0">
                  {" "}
                  Free Consultation and Zero Up-Front Fees
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 mt-3">
            <CustomSelect getDebtAmount={getDebtAmount} validationError={validationError}/>
          </div>
          {validationError && (
            <div className="text-center mt-3" style={{ color: "red" }}>
              {validationError}
            </div>
          )}
      
        </div>
      </div>
    </>
  );
};

export default Step1;
