import React from 'react';
import '../assets/css/ButtonLoader.css'; // Import the CSS file for styling

const ButtonLoader = () => {
  return (
    <div className="button-loader">
      <div className="loader"></div>
    </div>
  );
};

export default ButtonLoader;
