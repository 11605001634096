import React from 'react';

import img from '../assets/img13.svg'
import { useNavigate } from 'react-router-dom';
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

const Thankspage1 = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid p-0 bg-imge position-relative d-flex flex-column justify-content-between" style={{ minHeight: '100vh' }}>
                <img src={img} className="position-absolute inner_imges" alt="" />
               
               
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />

                <div className="container">
                    {/* ---------main-sec-1----- */}
                    <div className="row justify-content-center  ">
                        <div className="col-lg-7 col-md-10 col-12 mt-5 position-relative " style={{ zIndex: 3 }} >
                            <h6 className="font_30 font_500 font-DM-Sans">
                                HEARARE YOUR MATCHING <br /> PORS:
                            </h6>
                            <h5>Featured:(Book In-Person Estimate):</h5>
                            <div className='bg-blur rounded-3 p-3 mt-3'>
                                <div className='row justify-content-center'>
                                    <div className="col-12 text-end">
                                        <div className=''>

                                            <button type='button' className='btn-green p-2 rounded-3'> <FaCheckCircle /> VERIFIED CONTRACTOR</button>
                                        </div>
                                    </div>
                                    <div className='col-10'>
                                        <p className=" font-DM-Sans mt-3 question">
                                            West Point Builders

                                        </p>
                                        <p className=" font-DM-Sans mt-3 question">
                                            888-217-7738
                                        </p>
                                        <p className=" font-DM-Sans mt-3 question">
                                            Los Angeles Roofing & Home Improvement Company
                                            4.9 Stars on Google
                                        </p>
                                        <div className='d-flex justify-content-between mb-3'>

                                            <p className=" font-DM-Sans mt-4  question">
                                                <FaCalendarAlt className='me-1 CHOOSE' /> CHOOSE DATE
                                            </p>
                                            <p className=" font-DM-Sans mt-4 question">
                                                see more dates
                                            </p>
                                            <p className=" font-DM-Sans mt-4 question">
                                                <FaCircleChevronRight fontSize={20} color='white' />
                                            </p>
                                        </div>

                                        <div>
                                            <p className=" font-DM-Sans mt-3 question" >A team member from West Point Builder will reach out to you shortly</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mb-3'>

                                    <button className="btn-fill font_15 w-25 ok_btn mt-4" type="submit" onClick={() => navigate('/')}>
                                        Home
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />
                {/*footer*/}
                <footer className="container">
                    <p className="font_20 font-DM-Sans">
                        <i className="fa-solid fa-phone-volume me-2" />
                        Need Help?
                    </p>
                    <h3 className="font_22 font_700 font-DM-Sans">310-307-1916</h3>
                    <hr style={{ border: "1px solid #FFFFFF" }} />
                    <div className="text-center">
                        <p>© Copyright 2023 | Contractor. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>

        </>
    );
}

export default Thankspage1;
