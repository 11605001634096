import React from 'react';
import { usePlacesWidget } from "react-google-autocomplete";

const AutoComplete = ({ getMapAddress }) => {
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyAk3IEGbLHjlHjf5nJTEk6aXed_fe-vLFc",
        onPlaceSelected: (place) => {
            getMapAddress(place?.formatted_address)
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: 'US' }
        },
    });

    return (
        <div className="input-container">
            <input
                ref={ref}
                type="text"
                name="address"
                id="address"
                autoComplete='address'
                className="form-control py-2 pdr-input rounded-4"
                placeholder="Enter your Address"
                defaultValue=''
            />
            <label className='pdr-label' htmlFor="address">Address *</label>
        </div>
    )
}

export default AutoComplete;
