import React from 'react'
import starone from '../assets/blue_star.svg'
import google from '../assets/google.svg'
import fivestar from '../assets/starfiv.svg'
import affair from '../assets/consumer-affairs.svg'
import trust from '../assets/trust-pilot.svg'
const Count = () => {
  return (
    <div>

<div className="container-fluid mt-5 mb-5 bg-colorCount p-5">
        <div className="row justify-content-center">
          <h1 className='font-35'>You Can Count On Us</h1>
          <div className="col-8">
          <div className="d-flex justify-content-center mt-3">
           <img src={starone} className='stars' alt="" /> 
           <img src={starone} className='stars' alt="" /> 
           <img src={starone} className='stars' alt="" /> 
           <img src={starone} className='stars' alt="" /> 
           <img src={starone} className='stars' alt="" /> 
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6 col-12">
              <div>
              <img src={google} alt="" />
              <img src={fivestar} alt="" />
             <p><b>4.6</b>  – 2,000+ Reviews</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div>
              <img src={affair} alt="" />
              <img src={fivestar} alt="" />
             <p><b>4.82</b>   – 46,000+ Reviews</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div>
              <img src={trust} alt="" />
              <img src={fivestar} alt="" />
             <p><b>4.8</b> – 31,000+ Reviews</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        
      </div>
      <h1 className='textblued'> Your Success Is Our Success</h1>
    </div>
  )
}

export default Count