import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "USD",
    label: "$",
  },
];
const Step4 = ({ validationError ,firstNameFamily, getFirstNameFamily, lastNameFamily, getLastNameFamily, getDateOfBirthFamily}) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className=" col-12">
          <h1 className="textdark font-42"> Add A Spouse Or Family <br /> Member To The Plan</h1>
          <p className="font-18 textccc"> While not required, applying with a family member can allow you to pass the savings on to them as well.</p>
        </div>
        <div className="col-md-5 col-12 mt-5">
          <div className="row p-5">
            <div className="col-md-12">
              <div class="input-container">
                <input
                  value={firstNameFamily}
                  onChange={(e) => getFirstNameFamily(e)}
                  style={{ border: validationError && firstNameFamily == '' && "1px solid red" }}
                  value={firstNameFamily}
                  type="text"
                  name="firstName"
                  id="firstName"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && firstNameFamily == null ? 'red-border' : ''}`}
                  placeholder="Enter First Name"
                  defaultValue=''
                />
                <label className='pdr-label' for="address">First Name</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="input-container">
                <input
                  value={lastNameFamily}
                  onChange={(e) => getLastNameFamily(e)}
                  value={lastNameFamily}
                  type="text"
                  name="lastName"
                  id="lastName"
                  className={`form-control py-2 pdr-input rounded-4 ${validationError != null && lastNameFamily == null ? 'red-border' : ''}`}
                  placeholder="Enter Last Name"
                  defaultValue=''
                />
                <label className='pdr-label' for="address">Last Name</label>
              </div>
            </div>
            <div className="col-md-12 mt-3">
                <div class="input-container">
                  <input
                    onChange={(e) => getDateOfBirthFamily(e)}
                    type="date"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    className="form-control py-2 pdr-input rounded-4"
                    placeholder="Date of Birth"
                    defaultValue=''
                  />
                  <label className='pdr-label' for="dateOfBirth">Date of Birth</label>
                </div>
              </div>
          </div>
          {validationError && (
            <div className="text-center mt-3" style={{ color: "red" }}>
              {validationError}
            </div>
          )}
        </div>

        {validationError && (
          <div className="text-center mt-3" style={{ color: "red" }}>
            {validationError}
          </div>
        )}
      </div>
    </>
  );
};

export default Step4;
