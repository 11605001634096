import './App.css';
import Home from './Pages/Home';
import { Route, Routes } from 'react-router-dom';
import ApplicationStep1 from './components/ApplicationStep1';
import ApplicationStep2 from './components/ApplicationStep2';
import ApplicationStep3 from './components/ApplicationStep3';
import Thankspage1 from './Pages/Thankspage1';
import About from './Pages/About';
import Services from './Pages/Services';
import Term from './components/Term';
import Privacy from './components/Privacy';
function App() {
  return (
   <>
   <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/application1' element={<ApplicationStep1/>}/>
    <Route path='/application2' element={<ApplicationStep2/>}/>
    <Route path='/application3' element={<ApplicationStep3/>}/>
    <Route path='/quote/success' element={<Thankspage1/>}/>
    <Route path='/term' element={<Term/>}/>
    <Route path='/privacy' element={<Privacy/>}/>
    <Route path='/privacy' element={<Privacy/>}/>

   </Routes>
   </>
  );
}

export default App;
