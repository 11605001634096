import React from 'react'
import line from '../assets/img13.svg'
import Footer from './Footer'
import NavHeader from '../components/NavHeader';
const Term = () => {
   return (
      <div>
      <NavHeader />
         <div className="conatiner-fluid bg-img  backgroundcolor position-relative pb-5">
            <img src={line} className="position-absolute inner_imges" alt="" />
            <div className="container pt-5 mb-5">
               <div className="row justify-content-center mt-5">
                  <div className="col-lg-10 col-md-6 col-12 ">
                     <div>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Terms and Conditions</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           Welcome to Pure Debt Relief! These terms and conditions outline the rules and regulations for the use of our website https://www.puredebtrelief.com/ and the services provided by Pure Debt Relief
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           By accessing this Site, you accept these terms and conditions in full. Do not continue to use the Site if you do not accept all of the terms and conditions stated on this page.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Intellectual Property</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           Unless otherwise stated, Pure Debt Relief and/or its licensors own the intellectual property rights for all material on the Site. All intellectual property rights are reserved. You may view and/or print pages from the Site for your own personal use subject to restrictions set in these terms and conditions.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Restrictions</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           You are specifically restricted from all of the following:
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>1.</span>Publishing any material from the Site in any other media.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>2.</span>Selling, sublicensing, and/or otherwise commercializing any material from the Site.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>3.</span>Using the Site in any way that is or may be damaging to the Site.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>4.</span>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to the Site.
                        </p>

                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>User Content</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           Our Site allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material. By posting or submitting content, you grant Pure Debt Relief a non-exclusive, royalty-free, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Limitation of Liability</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           In no event shall Pure Debt Relief nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this Site, whether such liability is under contract, tort, or otherwise.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Termination</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We reserve the right to terminate your access to the Site, without any advance notice.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Governing Law</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction], and you irrevocably submit to the exclusive jurisdiction of the courts in that state or location.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Changes to This Agreement</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We reserve the right to modify these terms and conditions at any time. Your decision to continue to visit and make use of the Site after such changes have been made constitutes your formal acceptance of the new terms and conditions.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Contact Us</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           If you have any questions about these terms and conditions, please contact us at Connect@Connect.com
                        </p>
                     </div>
                  </div>

               </div>
            </div>
            <Footer />
         </div>
      </div>
   )
}

export default Term


