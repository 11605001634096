import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationModal = ({isOpen,handleClose,handleAddressConfirmation,mapAddress,dateOfBirth,streetAddress}) => {
  const handleConfirm = () => {
    // Perform the action you want to confirm here
    // For example, delete an item or submit a form
    console.log('Confirmed!');
    handleClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" // Adjust the size as needed (xs, sm, md, lg, xl)
        PaperProps={{
          style: {
            width: '100%', // Set a minimum width if needed
          },
        }}>
        <DialogTitle><h2>Confirm Your Details</h2></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3>Address:</h3> <p style={{fontSize:20}}>{mapAddress?.length > 0 ?
            mapAddress:
            streetAddress?.address+", "+streetAddress?.state+", "+streetAddress?.city          
            }</p>
            <h3>Date of Birth:</h3> <p style={{fontSize:20}}>{dateOfBirth}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddressConfirmation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
