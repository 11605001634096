import React from 'react'
import line from '../assets/img13.svg'
import Footer from './Footer'
import NavHeader from '../components/NavHeader';
const Privacy = () => {
   return (
      <div>
      <NavHeader />
         <div className="conatiner-fluid bg-img  backgroundcolor position-relative pb-5">
            <img src={line} className="position-absolute inner_imges" alt="" />
            <div className="container pt-5 mb-5">
               <div className="row justify-content-center mt-5">
                  <div className="col-lg-10 col-md-6 col-12 ">
                     <div>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Privacy Policy</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           Pure Debt Relief is committed to protecting the privacy and security of your personal information.
                           This Privacy Policy describes how we collect, use, and disclose your personal information when you visit our website
                           https://www.puredebtrelief.com/  and use our services.
                        </p>
                     </div>

                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Restrictions</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           You are specifically restricted from all of the following:
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>1.</span>Publishing any material from the Site in any other media.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>2.</span>Selling, sublicensing, and/or otherwise commercializing any material from the Site.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>3.</span>Using the Site in any way that is or may be damaging to the Site.
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>4.</span>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to the Site.
                        </p>

                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>How We Use Your Information</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We may use the information collected for various purposes, including:
                        </p>
                        <p className="font_20 font-DM-Sans color_gray">
                           <span>1.</span>To provide, maintain, and improve our services.
                           <span>2.</span>To personalize your experience on our Site.
                           <span>3.</span>To communicate with you, including responding to your inquiries and providing updates about our services.
                           <span>4.</span>To comply with legal obligations.</p>

                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Information Sharing and Disclosure</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share your information with trusted third parties who assist us in operating our website or servicing you.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Security</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We take reasonable measures to protect the confidentiality and security of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Your Choices</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           You can choose not to provide certain information, but this may limit your ability to use certain features of our Site. You may opt-out of receiving promotional emails from us by following the instructions in those emails.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Changes to this Privacy Policy</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                        </p>
                     </div>
                     <div className='mt-5 mb-3'>
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Contact Us</h3>
                        <p className="font_20 font-DM-Sans color_gray">
                           If you have any questions about this Privacy Policy, please contact us at Connect@Connect.com
                        </p>
                     </div>
                  </div>

               </div>
            </div>

            <Footer />
         </div>
      </div>
   )
}

export default Privacy