import React from 'react'

const Services2 = (props) => {
    return (
        <div className="container mt-5 pt-5">
            <div className="row justify-content-center flexflow reverse-sm-column">
                <div className="col-lg-6 col-md-6 col-12">
                    <h3 className='font_32 font_700 font-Bold-Ivy colorblack'> <span className='colorgreen'> {props.headFirst}</span> {props.head1}
                        <span className=' colorgreen'> {props.head2}</span></h3>
                    <p className="font_18 font-DM-Sans colorblack"> {props.para1}
                        <span className='colorgray'>
                            {props.para2}</span>
                        <span className='font_18 font-DM-Sans colorblack'>{props.para3}</span>
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-12 text-end ">
                    <img src={props.img} className='w-75' alt="" />
                </div>
            </div>
        </div>
    )
}

export default Services2