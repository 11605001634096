import React from 'react';
import img1 from '../assets/pic16.svg'
import img2 from '../assets/pic17.svg'
import img3 from '../assets/pic18.svg'
const Customer = () => {
    return (      
            <>
                {/*section-Why Our Customers */}
                <div className="container pt-5 pb-5 text-center">
                    <h3 className="font_42 font_700 font-Bold-Ivy">
                        Why Our Customers Choose <span className="green_color">Contractor?</span>
                    </h3>
                    <p className="font_16 font-DM-Sans color_gray">
                        Multiple business capital options carefully crafted for your needs.
                    </p>
                    <div className="row justify-content-center text-start pt-5">
                        <div className="col-lg-4 col-md-8 col-12 inner_line px-4">
                            <img src={img1} className="inner-imges" alt="" />
                            <h3 className="font_20 font_600 font-Bold-Ivy mt-3">Over $4B Funded</h3>
                            <p className="font_16 font-DM-Sans mt-3 color_gray">
                                We have funded $4B with our partners, and actively funded over $5M
                                each month.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-8 col-12 inner_line px-4">
                            <img src={img2} className="inner-imges" alt="" />
                            <h3 className="font_20 font_600 font-Bold-Ivy mt-3">
                                98.7% Approval Rate
                            </h3>
                            <p className="font_16 font-DM-Sans mt-3 color_gray">
                                We have funded $4B with our partners, and actively funded over $5M
                                each month.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-8 col-12  px-4">
                            <img src={img3} className="inner-imges" alt="" />
                            <h3 className="font_20 font_600 font-Bold-Ivy mt-3">
                                40,000 Businesses Funded
                            </h3>
                            <p className="font_16 font-DM-Sans mt-3 color_gray">
                                We provide our expertise in small business structure to help you grow
                                your business.
                            </p>
                        </div>
                    </div>
                </div>
            </>    
    );
}

export default Customer;
