import React from "react";
import navlogo from "../assets/logo.png";
import { Link } from "react-router-dom";

const NavHeader = () => {
  return (
    <div>
      <div className="container-fluid p-1">
        <div className="navheader">
          <div className="container p-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
              <Link to={'/'}>
                <img src={navlogo} width={100} alt="" />
                </Link>
              </div>
              {/* <div className="textwhite phoneno rounded-5 p-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <path
                      d="M28.35 20.6333C26.3 20.6333 24.3167 20.3 22.4667 19.7C21.8833 19.5 21.2333 19.65 20.7833 20.1L18.1667 23.3833C13.45 21.1333 9.03333 16.8833 6.68333 12L9.93333 9.23333C10.3833 8.76667 10.5167 8.11667 10.3333 7.53333C9.71667 5.68333 9.4 3.7 9.4 1.65C9.4 0.75 8.65 0 7.75 0H1.98333C1.08333 0 0 0.4 0 1.65C0 17.1333 12.8833 30 28.35 30C29.5333 30 30 28.95 30 28.0333V22.2833C30 21.3833 29.25 20.6333 28.35 20.6333Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
