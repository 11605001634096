import React from 'react'

import line from '../assets/img13.svg'
import img from '../assets/ime.png'
import imges from '../assets/imges.svg'
import imges3 from '../assets/imges3.svg'
import imges1 from '../assets/shortterm.png'
import imges2 from '../assets/equipment.png'
import imges5 from '../assets/sba.png'
import imges6 from '../assets/imges6.svg'
import ToDay from '../components/ToDay'
import Footer from '../components/Footer'
import Services1 from '../components/Services1'
import Services2 from '../components/Services2'
import { useEffect } from 'react'
const Services = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
          });
      }, [])
    return (
        <>
            {/* {section-Services-} */}
            <div className="conatiner-fluid bg-img backgroundcolor position-relative pb-5">
                <img
                    src={line}
                    className="position-absolute inner_imges"
                    alt=""
                />
               
                {/* Business Loans*/}
                <div className="container pt-5">
                    <div className="row justify-content-center mt-5">

                        <div className="col-lg-6 col-md-6 col-12 ">
                            <h3 className='font_32 font_700 font-Bold-Ivy '>We help you with the<span className='coloryellow'><br />
                                Business Loans</span> </h3>
                            <p className="font_20 font-DM-Sans color_gray">
                                Apply today and have access to capital from $20,000 up to  $20
                                million with in hour’s of being qualified.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-end">
                            <img src={img} className='w-75 position-relative z-3' alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* --Accounts-- */}
            <Services1 img={imges} head1="Accounts" head2='Receivables Loans' para1='Accounts receivable financing, also called invoice factoring, is a type of business funding in which companies sell their unpaid invoices to a factoring company at a discounted rate in exchange for immediate cash.'
                para2='For example, if you have an invoice for $1,000 that will take your customer 30 days to pay, you can factor the invoice and receive $800 in cash upfront. The factoring company will then collect payment from your customer and remit the remaining balance to you, minus a factoring fee.
Factoring fees are typically based on the invoice’s net terms, which is the length of time your customer has to pay the invoice. They can range from 1-5% of the invoice total, depending on the industry and other factors. However, you can often negotiate lower rates if you have a strong relationship with your factor.' para3='Accounts receivable financing is a flexible funding option that can be used for a variety of purposes, including covering payroll or expanding your business. It’s also relatively easy to qualify for, as long as you have unpaid invoices from creditworthy customers.' />
            {/* Line of Credit */}
            <Services2 img={imges3} head1="Business " head2='Line of Credit' para1='Equipment term loans from Qualifier offer a long-term financing solution for the purchase of equipment with fixed or variable rates. With flexible repayment terms, you can choose a schedule that works for you and your business without sacrificing near-term cash flow.'
                para2='Once the loan is paid in full, the borrower owns the equipment free of any lien. Whether it’s heavy machinery, computers, or business vehicles, Qualifier offers competitive fixed and variable-rate financing programs that can help you and your business get the equipment you need. Plus, you’ll get the security of having a fixed repayment schedule that affords your business greater security and predictability.
Equipment finance from Qualifier can help you get the equipment your business needs to grow and succeed.' />
            {/* Short Term */}
            <Services1 img={imges1} headFirst="Short Term" head1='Loans' para1='Short-term business loans are financial products designed to provide businesses with quick access to funding for a limited period of time, usually ranging from several months to a year. These loans are typically used to meet immediate and pressing financial needs, such as covering operational expenses, purchasing inventory, or addressing unexpected expenses. ' para2='Unlike long-term loans, short-term loans have a faster application and approval process, and the funds are usually made available to the borrower within moments of the day your loan is approved.' />
            {/*Equipment*/}
            <Services2 img={imges2} headFirst="Equipment" head1='Loans' para1='Equipment term loans from Qualifier offer a long-term financing solution for the purchase of equipment with fixed or variable rates. With flexible repayment terms, you can choose a schedule that works for you and your business without sacrificing near-term cash flow.'
                para2='Once the loan is paid in full, the borrower owns the equipment free of any lien. Whether it’s heavy machinery, computers, or business vehicles, Qualifier offers competitive fixed and variable-rate financing programs that can help you and your business get the equipment you need. Plus, you’ll get the security of having a fixed repayment schedule that affords your business greater security and predictability.
Equipment finance from Qualifier can help you get the equipment your business needs to grow and succeed.' />
            {/* SBA loans */}
            <Services1 img={imges5} headFirst="SBA" head1='Loans' para1='SBA loans are long-term small business loans partially guaranteed by the government.'
                para2='The SBA is a federal agency committed to furthering the growth and development of small businesses and partners with lenders nationwide in their loan programs. SBA loans offer business owners the opportunity to take advantage of favorable terms as part of the SBA program, while removing some of the barriers to traditional financing options.
SBA 7A Loans are the most common type of SBA loan and can be used for a variety of purposes, including working capital, refinancing, equipment, and real estate. Terms for SBA 7A loans are generally longer than for conventional loans, giving borrowers more time to repay the loan.' para3='As a Preferred SBA Lender, our program offers a faster loan process with reduced paperwork for businesses that have been in operation for less than two years. We customize SBA loans based on the unique needs of the business owner. The funds may be used for vehicle purchase, equipment, inventory, or business expansion.' />
            {/* Term loans */}
            <Services2 img={imges6} headFirst="Term" head1='Loans' para1='Equipment term loans from Qualifier offer a long-term financing solution for the purchase of equipment with fixed or variable rates. With flexible repayment terms, you can choose a schedule that works for you and your business without sacrificing near-term cash flow.'
                para2='Once the loan is paid in full, the borrower owns the equipment free of any lien. Whether it’s heavy machinery, computers, or business vehicles, Qualifier offers competitive fixed and variable-rate financing programs that can help you and your business get the equipment you need. Plus, you’ll get the security of having a fixed repayment schedule that affords your business greater security and predictability.
Equipment finance from Qualifier can help you get the equipment your business needs to grow and succeed.' />
            <ToDay />
            <Footer />
        </>
    )
}

export default Services