import React from 'react';
import work from "../assets/talk-to-us.svg";
import work1 from "../assets/affordable-plan.svg";
import work2 from "../assets/performance-money-increase@250x250.svg";

const Howitwork = () => {
    return (
        <div className="row justify-content-center p-5">
            <h1 className="font-42 textdark fw-600 mt-5"> How It Works </h1>
            <div className="col-md-4 col-sm-6 mt-3">
                <div>
                    <img src={work} alt="" />
                </div>
                <div className="mt-3">
                    <h1 className="font-28 textdark fw-600"> Submit The Form For A Free Consultation</h1>
                    <p> We’ll call you in the next few minutes to talk about your options.</p>

                </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-3">
                <div>
                    <img src={work1} alt="" />
                </div>
                <div className="mt-3">
                    <h1 className="font-28 textdark fw-600"> We Build An Affordable Plan That Works For You</h1>
                    <p> Custom fit for your personal budget and individual timeline</p>

                </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-3">
                <div>
                    <img src={work2} width={50} alt="" />
                </div>
                <div className="mt-3">
                    <h1 className="font-28 textdark fw-600"> You Get Out Of Debt Faster And Take Back Your Life</h1>
                    <p> You could be debt free in as little as 24-48 months</p>

                </div>
            </div>

        </div>
    )
}

export default Howitwork
