import React from 'react';
import lives from "../assets/Michelle-640x285.jpg"
import lives1 from "../assets/Eric_thumbnail-640x285.jpg"
import lives2 from "../assets/NDR_LHendricks_Nov21-640x285.jpg"

const Savelives = () => {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <h1 className="textwhitee mb-5"> We’ve Transformed The Lives Of Hundreds Of Thousands Of People</h1>
          <div className="col-lg-4 col-md-6 col-12 mt-5">
            <div className="livescard cardcontent h-100 mt-3">
              <img src={lives} className="w-100 imgcard" alt="" />
              <div className=" p-4">
                <p className="font-12"> Michelle V., Military college advisor, single mother of 2</p>
                <h3 className="quote"> “The anxiety is gone, I am credit card debt-free. And that right there, I never thought I would be able to say those words, and it just feels so good.”</h3>
                <div className="row mt-lg-5">
                  <div className="col-4 line">
                    <div className="text-start">
                      <p className="font-12"> Total Debt </p>
                      <h6 className="text-start font-18 fw-bold"> $19,164</h6>
                    </div>
                  </div>
                  <div className="col-4 line">
                    <div>
                      <p className="font-11"> Monthly Payment</p>
                      <h6 className="text-start font-18 fw-bold"> $398</h6>
                    </div>
                  </div>

                  <div className="col-4 line">
                    <div>
                      <p className="font-12 text-start"> Savings</p>
                      <h6 className="text-start font-18 fw-bold"> 23%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-5">
            <div className="livescard cardcontent h-100 mt-3">
              <img src={lives1} className="w-100 imgcard" alt="" />
              <div className=" p-4">
                <p className="font-12">Eric H., Government contractor, Harley enthusiast</p>
                <h3 className="quote"> “I’ve been in a combat environment, and there you have a team … Pure Debt Relief started working with me and suddenly, I had my team. I had my support structure to push myself forward.”</h3>
                <div className="row mt-lg-4">
                  <div className="col-4 line">
                    <div className="text-start">
                      <p className="font-12"> Total Debt </p>
                      <h6 className="text-start font-18 fw-bold"> $47,519</h6>
                    </div>
                  </div>
                  <div className="col-4 line">
                    <div>
                      <p className="font-11"> Monthly Payment</p>
                      <h6 className="text-start font-18 fw-bold"> $765</h6>
                    </div>
                  </div>

                  <div className="col-4 line">
                    <div>
                      <p className="font-12 text-start"> Savings</p>
                      <h6 className="text-start font-18 fw-bold"> 37%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-5">
            <div className="livescard cardcontent h-100 mt-3">
              <img src={lives2} className="w-100 imgcard" alt="" />
              <div className=" p-4">
                <p className="font-12"> Lindsay H., Working, single mom</p>
                <h3 className="quote"> “I was just one of those people who kept waiting, thinking there would be a solution next month. And I wish I would’ve called <br /> sooner.”</h3>
                <div className="row mt-lg-5">
                  <div className="col-4 line">
                    <div className="text-start">
                      <p className="font-12"> Total Debt </p>
                      <h6 className="text-start font-18 fw-bold"> $32,693</h6>
                    </div>
                  </div>
                  <div className="col-4 line">
                    <div>
                      <p className="font-11"> Monthly Payment</p>
                      <h6 className="text-start font-18 fw-bold"> $506</h6>
                    </div>
                  </div>

                  <div className="col-4 line">
                    <div>
                      <p className="font-12 text-start"> Savings</p>
                      <h6 className="text-start font-18 fw-bold"> 29%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Savelives
