import React from 'react';

import HeroSection from '../components/HeroSection';
import { useEffect } from 'react';
import NavHeader from '../components/NavHeader';
// import Footer from '../components/Footer';

const Home = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
        });
    }, [])


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
        });
    }
    return (
        <>
            <div className="container-fluid bg-img p-0">
                <NavHeader />
                <HeroSection />
                <br />
            </div>
        </>

    );
}

export default Home;
