import React from 'react'

const Step8 = ({getFallingBehindPayment}) => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-8 col-md-8">
                    <h1 > Are you fallings behind on payments? </h1>
                </div>
                <div className='col-md-5 col-lg-5 col-12'>
                    <div class="d-flex justify-content-center">
                        <div class="nav flex-column nav-pills me-3" style={{padding:'30px'}} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button onClick={()=>getFallingBehindPayment('No')} class="nav-link active fw-bold" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">No</button>
                            <button onClick={()=>getFallingBehindPayment('Yes')} class="nav-link mt-3 fw-bold" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Step8
