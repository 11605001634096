import React, { useEffect, useState } from "react";
import '../custom.css';
import '../style.css';
import debtlogo from "../assets/debtlogo.svg";
import debtImage from "../assets/debt-img.jpg";
import debtlogo2 from "../assets/debtlogo3.svg";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { Link, json, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Alert } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import ButtonLoader from "./ButtonLoader";
// import googleLogo from '../assets/logo-google.png';
// import bbbLogo from '../assets/logo-bbb.png';
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step9 from "./Step9";

import { Accordain } from "./Accordain";
import Count from "./Count";
import Fithsection from "./Fithsection";
import Fourthsec from "./Fourthsec";
import Savelives from "./Savelives";
import Sixthsec from "./Sixthsec";
import Faqs from "./Faqs";
import Howitwork from "./Howitwork";
import Footer from "./Footer";
import ConfirmationModal from "./ConfirmationModal";
import Before from "./Before";
import Step8 from "./Step8";
const steps = ["step1", "step2", "step3", "step4", "step5", 'step6','step7'];

export default function MultiStep() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addressConfirm, setAddressConfirm] = useState(false);

  // ------STATES--------
  const [activeStep, setActiveStep] = useState(0);
  const [validationError, setValidationError] = useState(null);

  const [debtAmount, setDebtAmount] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const [firstNameFamily, setFirstNameFamily] = useState(null);
  const [lastNameFamily, setLastNameFamily] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);

  const [mapAddress, setMapAddress] = useState(null);
  const [streetAddress, setStreetAddress] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfBirthFamily, setDateOfBirthFamily] = useState(null);

  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [fallingBehindPayment, setFallingBehindPayment] = useState(null);

  const handleNext = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("here", activeStep);
    if (activeStep == 0) {
      if (debtAmount?.length > 0) {
        // getLocationAgainstdebtAmount(debtAmount)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setValidationError(null);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setValidationError("Please Select Debt Amount!")
      }
    } else if (activeStep == 1) {

      // ------------step-2-----
      if (firstName?.length > 0 && lastName?.length > 0 && email?.length > 0 && phoneNumber?.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false);
        setValidationError(null);
      } else {
        setIsLoading(false)
        setValidationError("All the fields are required")
      }
    } else if (activeStep == 2) {
      if (dateOfBirth?.length > 0) {
        setAddressConfirm(true);
        setIsLoading(false);
        setValidationError(null);
      } else {
        setIsLoading(false)
        setValidationError("All the fields are required")
      }
    } else if (activeStep == 3) {
      // if (socialSecurityNumber?.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsLoading(false);
      setValidationError(null);
      // } else {
      //   setIsLoading(false)
      //   setValidationError("All the fields are required")
      // }
    } else if (activeStep == 4) {
      // if (socialSecurityNumber?.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsLoading(false);
      setValidationError(null);
      // } else {
      //   setIsLoading(false)
      //   setValidationError("All the fields are required")
      // }
    } else if (activeStep == 5) {
      // if (socialSecurityNumber?.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsLoading(false);
      setValidationError(null);
      // } else {
      //   setIsLoading(false)
      //   setValidationError("All the fields are required")
      // }
    } else if (activeStep == 6) {
      // if (socialSecurityNumber?.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsLoading(false);
      setValidationError(null);
      // } else {
      //   setIsLoading(false)
      //   setValidationError("All the fields are required")
      // }
    } else {
      window.location.reload();
    }
  };


  const handelSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      debtAmount: debtAmount,
      dateOfBirth: dateOfBirth,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber,
      streetAddress: mapAddress?.length > 0 ? mapAddress : streetAddress?.address + ", " + streetAddress?.state + ", " + streetAddress?.city,
      familyDetail: "Name: "+firstNameFamily+" "+lastNameFamily+", Date of Birth: "+dateOfBirthFamily,
      employmentStatus: employmentStatus,
      fallingBehindPayment:fallingBehindPayment
    };
    console.log(data, "================Form Data");
    let config = {
      method: "post",
      redirect: "follow",
      url: "https://script.google.com/macros/s/AKfycbwuaERrIV0-xFQ4HSznqKZQYPpTPjrGK0G2ZAUQhSI6eTQ14goYmbou5BDfZwO1cg_z/exec",
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false);
        setValidationError(null);
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log(amount);
  };


  //Step 1 data
  const getDebtAmount = (data) => {
    setDebtAmount(data);
  };
  //Step 2 data
  const getFirstName = (data) => {
    setFirstName(data.target.value);
  };
  const getLastName = (data) => {
    setLastName(data.target.value);
  };
  const getPhoneNumber = (data) => {
    setPhoneNumber(data);
  };
  const getEmail = (data) => {
    setEmail(data.target.value);
  };
  //Step 3 data
  const getMapAddress = (data) => {
    setMapAddress(data);
  };
  const getDateOfBirth = (data) => {
    setDateOfBirth(data.target.value);
  };
  const getStreetAddress = (data) => {
    console.log(data.target.name);
    let updatedAddress = { ...streetAddress, [data.target.name]: data.target.value };
    setStreetAddress(updatedAddress);
  };

  //Step 4 data
  const getFirstNameFamily = (data) => {
    setFirstNameFamily(data.target.value);
  };
  const getLastNameFamily = (data) => {
    setLastNameFamily(data.target.value);
  };
  const getDateOfBirthFamily = (data) => {
    setDateOfBirthFamily(data.target.value);
  };


  //Step 5 data
  const getEmploymentStatus = (data) => {
    setEmploymentStatus(data)
    console.log(data)
  };

  //Step 6 data
  const getFallingBehindPayment = (data) => {
    console.log(data)
    setFallingBehindPayment(data)
  };
  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step1
            getDebtAmount={getDebtAmount}
            debtAmount={debtAmount}
            validationError={validationError}
          />
        );
      case 1:
        return (
          <Step2
            validationError={validationError}
            firstName={firstName}
            getFirstName={getFirstName}
            lastName={lastName}
            getLastName={getLastName}
            getPhoneNumber={getPhoneNumber}
            phoneNumber={phoneNumber}
            getEmail={getEmail}
            email={email}
          />
        );
      case 2:
        return (
          <Step3
            validationError={validationError}
            getDateOfBirth={getDateOfBirth}
            dateOfBirth={dateOfBirth}
            getMapAddress={getMapAddress}
            getStreetAddress={getStreetAddress}
            streetAddress={streetAddress}
          />
        );
      case 3:
        return (
          <Step4
            firstNameFamily={firstNameFamily}
            getFirstNameFamily={getFirstNameFamily}
            lastNameFamily={lastNameFamily}
            getLastNameFamily={getLastNameFamily}
            getDateOfBirthFamily={getDateOfBirthFamily}
            validationError={validationError}
          />
        );
      case 4:
        return (
          <Step6
            getEmploymentStatus={getEmploymentStatus}
            getStreetAddress={getStreetAddress}
            streetAddress={streetAddress}
            validationError={validationError}
          />
        );
      case 5:
        return (
          <Step8
          getFallingBehindPayment={getFallingBehindPayment}
            validationError={validationError}
          />
        );
      case 6:
        return (
          <Step9
            firstName={firstName}
            lastName={lastName}
            validationError={validationError}
          />
        );
        case 7:
        return (
          <Step7
            firstName={firstName}
            lastName={lastName}
            validationError={validationError}
          />
        );
      default:
        return <div style={{ marginTop: '14%' }}><h1>Thanks for your application</h1></div>;
    }
  }

  const handleClose = () => {
    setAddressConfirm(!addressConfirm);
  };
  const handleAddressConfirmation = () => {
    setAddressConfirm(!addressConfirm);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmationModal mapAddress={mapAddress} dateOfBirth={dateOfBirth} streetAddress={streetAddress} isOpen={addressConfirm} handleClose={handleClose} handleAddressConfirmation={handleAddressConfirmation} />
      <ToastContainer />
      <Stepper className="Numbers" activeStep={activeStep}>
        {/* Stepper content */}
      </Stepper>
      <React.Fragment>
        <form onSubmit={handelSubmit}>
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-12 text-center mt-lg-0 mt-4">
                <div
                  className={`bg-blur mt-3 ${activeStep === 1 || activeStep === 2 ? "" : ""
                    }`}
                >
                  {_renderStepContent(activeStep)}
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={activeStep === 6 ? handelSubmit : handleNext}
                      className={`custom-btn btn-fill m-3 mt-3 ${activeStep === 1 || activeStep === 2
                        ? "btn-fill-empty"
                        : ""
                        }`}
                    >
                      {isLoading ? (
                        <ButtonLoader />
                      ) : activeStep === steps.length - 1 ? (
                        "Finish"
                      ) : activeStep === 0 ? (
                        "Let's Go"
                      )
                        : activeStep === 3 ? (
                          "Next"
                        ) : activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 ? (
                          "Submit"
                        ) : (
                          "Back to Home"
                        )}
                    </button>
                  </div>


                  {/* {activeStep === 3 && (
                    <React.Fragment>
                      <div className="row justify-content-center">
                        <div className="col-8 m-3">
                          <a href=" "> No Thanks</a>
                        </div>
                      </div>
                    </React.Fragment>
                  )} */}

                  {activeStep === 1 && (
                    <React.Fragment>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <p className="mt-3 textccc font-16">
                            By clicking “Submit”, you agree that the phone
                            number and email address you are providing may be
                            used to contact you by Pure Debt Relief
                            (including auto-dialed/auto-selected and
                            prerecorded calls, as well as text/SMS messages).
                            Msg. and data rates apply, and your consent to
                            such contact/marketing is not required for
                            purchase. Msg frequency varies; max 2 texts per
                            day and 7 texts per week. We may also e-mail you
                            and you may let us know at any time if you are no
                            longer interested in hearing from us via a
                            particular communication platform.
                          </p>
                        </div>
                      </div>

                    </React.Fragment>
                  )}
                  {/* {activeStep === 2 && (
                    <React.Fragment>
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-11">
                          <p className="mt-3 textccc">
                            You understand that by clicking on the ‘Submit’
                            button you are providing ‘written instructions’ to
                            Pure Debt Relief (PDR) under the Fair Credit
                            Reporting Act authorizing PDR to obtain
                            information from your personal credit profile or
                            other information from Experian. You authorize PDR
                            to obtain such information solely to verify your
                            identity and display back credit information. This
                            is a secure soft credit pull and will NOT impact
                            your credit score.
                          </p>
                        </div>
                      </div>

                    </React.Fragment>
                  )} */}
                  {activeStep === 5 && (
                    <div className="container mt-3">
                      <div className="row justify-content-center" >
                        <div className="col-8">
                          <p className="text-center graycolor"> Monday - Friday: 8:00am - Midnight ET Saturday: 10:00am - 8:00pm ET Sunday: 9:00am - 10:00pm ET.</p>
                          <p className="text-center graycolor"> You can get a head start by calling the toll-free number above. </p>

                        </div>
                      </div>
                    </div>
                  )}

                  {activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 ? (
                    <React.Fragment>
                      <Count />
                      <Footer />
                    </React.Fragment>
                  ) : null}

                  
                  {activeStep === 1 && (
                    <React.Fragment>
                      <div className="container-fluid mt-5 wroking">
                        <Fithsection />
                      </div>
                      <div className="container-fluid dark">
                        <Sixthsec />
                      </div>
                    </React.Fragment>
                  )}

                  {/* how IT Works */}
                  {activeStep === 0 && (
                    <React.Fragment>
                      <div className="container-fluid wroking p-0 mt-5">
                        <Howitwork />
                      </div>
                      {/* FaQs */}
                      <div className="container mt-5">
                        <Faqs />
                      </div>
                      {/* Transfor Lives */}
                      <div className="container-fluid lives p-5 rounded-3 mt-5">
                        <Savelives />
                      </div>
                      {/* logos */}
                      {/* <div className="container-fluid mt-5">
                        <Fourthsec />
                      </div> */}
                      {/*  */}
                      <div className="container-fluid mt-5 wroking">
                        <Fithsection />
                      </div>
                      <div className="container-fluid mt-5 dark">
                        <Sixthsec />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    </Box>
  );
}
