import React from 'react';

import MultiStep from './MultiStep';
// import Footer from './Footer';

const HeroSection = () => {
    return (
        <div className="row main-container">
            <MultiStep />
        </div>

    );
}

export default HeroSection;
