import React, { useState } from 'react';
const CustomSelect = ({validationError,getDebtAmount}) => {
  const [selectedAmount, setSelectedAmount] = useState(0);
  const handleAmountChange = (e) => {
    getDebtAmount(e.target.value);
  };

  return (
    <div className='gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework track-dropdown-change_wrapper track-next-click_wrapper track-submit-click_wrapper' data-form-theme='legacy' data-form-index='0' id='gform_wrapper_281'>
      <div id='gf_progressbar_wrapper_281' className='gf_progressbar_wrapper'>
        <h3 className="gf_progressbar_title">Step <span className='gf_step_current_page'>1</span> of <span className='gf_step_page_count'>2</span>
        </h3>
        <div className='gf_progressbar gf_progressbar_blue' aria-hidden='true'>
          <div className='gf_progressbar_percentage percentbar_blue percentbar_50' style={{ width: "50%" }}>
            <span>50%</span>
          </div>
        </div>
      </div>
      <div className='gform-body gform_body'>
        <div id='gform_page_281_1' className='gform_page page-one' data-js='page-field-id-1'>
          <div className='gform_page_fields'>
            <ul id='gform_fields_281' className='gform_fields top_label form_sublabel_below description_below'>
              <li id="field_281_37" className="gfield gfield--type-select debt-dropdown gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible" data-js-reload="field_281_37">
                <div className='ginput_container ginput_container_select'>
                  <select style={{borderColor:validationError&&'red'}} onChange={handleAmountChange} name='input_37' id='input_281_37' className='medium gfield_select' aria-required="true" aria-invalid="false">
                    <option value selected='selected' className='gf_placeholder'>Select Debt Amount</option>
                    <option value='$0 - $10,000'>$0-$10,000</option>
                    <option value='$10,000-$15,000'>$10,000-$15,000</option>
                    <option value='$15,0000-$25,000'>$15,0000-$25,000</option>
                    <option value='$25,000-$50,000'>$25,000-$50,000</option>
                    <option value='$50,000+'>$50,000+</option>
                    
                  </select>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className='gform_page_footer top_label'>
            <button type='button' id='gform_next_button_281_2' className='gform_next_button gform-theme-button button' value='Continue' onclick='jQuery("#gform_target_page_number_281").val("2");  jQuery("#gform_281").trigger("submit",[true]); ' onkeypress='if( event.keyCode == 13 ){ jQuery("#gform_target_page_number_281").val("2");  jQuery("#gform_281").trigger("submit",[true]); } '>Let's Go</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
