import React from "react";

export const Accordain = () => {
    return (
        <div className="conatiner">
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed font-inter font-12 mt-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                        >
                            Why Trust Pure Debt Relief?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            <div class="tab-content">
                                <div>
                                    <p>Each month, thousands of clients start their path to financial stability with Pure Debt Relief. Since 2009, we’ve helped more than 600,000 of people get out of debt. We are the country’s top-rated debt settlement company and pride ourselves on our track record.</p>
                                    {/* <ul>
                                        <li>The votes are in! We are rated #1 by Forbes, Bankrate, Top Consumer Reviews, Top Ten Reviews, Consumers Advocate and Consumer Affairs</li>
                                        <li>Forbes Advisor and BankRate selected us as their top debt settlement company</li>
                                        <li>A+ rating from the Better Business Bureau</li>
                                        <li>Over 37,000 reviews on TrustPilot with a 4.7 out of 5 rating</li>
                                        <li>Active, accredited members of the American Association for Debt Resolution</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item mt-5">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed mt-3  font-inter font-12"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                        >
                            What Makes Me A Good Candidate For Debt Relief?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            <div class="tab-content">
                                <div>
                                    <p>If you’re drowning in high interest credit card debt and struggling to keep up with minimum payments, Pure Debt Relief can help you breathe again. Our program is a great choice for people with high levels of unsecured debt who are struggling with financial hardship, like a divorce or job loss. If you’re barely keeping up with your minimum payments and the balances on your accounts keep growing, then our program may be right for you. Why fight a losing battle against rising debt, when our debt relief services can help?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item mt-5">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed mt-3  font-inter font-12"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                        >
                            How Does Debt Relief Work?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            <div class="tab-content">
                                <div>
                                    <p>Debt settlement reduces your balance. Your debt is negotiated down, and you pay less than you owe. The creditor forgives the remaining balance in a transaction called a settlement.</p>
                                    <p><strong>Our program is easy to understand.</strong> Rather than paying your creditors, you’ll deposit a monthly payment to a Dedicated Savings Account, in your name and under your control. As funds build up, we’ll contact your creditors to negotiate and agree upon reduced balances. Because you’re no longer paying the creditor, they will likely view getting a reduced amount as better than risking no payment at all. Then, you’ll authorize us to use the funds in your Dedicated Savings Account to pay your creditors the lower, negotiated amounts. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item mt-5">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed mt-3  font-inter font-12"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                        >
                            Can I Afford This?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            <div className="tab-content">
                                <div> <p>
                                    Absolutely! Thousands of people every month find out that our program works for them. Pure Debt Relief goes far beyond making sure you can afford our program – we take the time to review your budget to create a custom program that will relieve both your financial burden and your stress.</p> </div> </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item mt-5">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed mt-3  font-inter font-12"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapsFive"
                            aria-expanded="false"
                            aria-controls="flush-collapsFive"
                        >
                            What Type Of Debt Does Pure Debt Relief Work With?
                        </button>
                    </h2>
                    <div
                        id="flush-collapsFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            <div class="tab-content">
                                <div>
                                    <p>We cover most debt, including:</p>
                                    <ul>
                                        <li>Credit Cards</li>
                                        <li>Personal Loans</li>
                                        <li>Lines of Credit</li>
                                        <li>Medical Bills</li>
                                        <li>Much More: Collections, Repossessions, Private Student Loans, Business Debt</li>
                                    </ul>
                                    <p>If you’re unsure about whether your debt would qualify, just give us a call.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
