import React from "react";
import logo from "../assets/contractor-scout-high-resolution-logo-white-transparent.png";
import aplus from "../assets/aplus.png";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
const Footer = () => {
  return (
    <>
      {/* --footer-- */}
      <footer className="container-fluid bg-colorwhite mt-5 p-4 rounded-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {/* <div className="col-md-6 col-6 mb-4">
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Apply </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> How It Works </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> About Us </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Client Stories </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Resources </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Blog </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Login </h6>
                  </Link>
                </div> */}
                <div className="col-md-6 col-6 mb-4">
                  {/* <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Contact Us </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Careers </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb- text-start fw-bold"> Corporate</h6>
                  </Link> */}
                  <Link to="/privacy">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Privacy Policies </h6>
                  </Link>
                  <Link to="/term">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Online Terms of Service </h6>
                  </Link>
                  {/* <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Visitor Information </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> News & Media </h6>
                  </Link> */}
                </div>
                {/* <div className="col-md-6 col-6 mb-4">
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Calculators </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Proven Results </h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> FAQs</h6>
                  </Link>
                  <Link to="">
                    <h6 className="mb-0 textwhite font-16 mt-3 mb-2 text-start fw-bold"> Sitemap </h6>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-end">
                {/* <img src={aplus} width={200} alt="" /> */}
                <div className="d-flex justify-content-end mt-5">
                  <div className="iconecolor social-icon rounded-circle"><FaFacebookF /></div>
                  <div className="iconecolor mx-2 social-icon rounded-circle"><RiTwitterXFill /></div>
                  <div className="iconecolor social-icon rounded-circle"><FaInstagram /></div>
                  <div className="iconecolor mx-2 social-icon rounded-circle"><FaLinkedinIn /></div>
                  <div className="iconecolor mx-2 social-icon rounded-circle"><FaYoutube /></div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ border: "2px solid #FFFFFF" }} />
          <div className="text-start">
            <h5 className="fw-bold textblue"> © 2024, Pure Debt Relief, All Rights Reserved.</h5>
            <p className="textblue" style={{fontSize:20}}> Please note that all calls with the company may be recorded or monitored for quality assurance and training purposes. *Clients who are able to stay with the program and get all their debt settled realize approximate savings of 46% before fees, or 25% including our fees, over 24 to 48 months. All claims are based on enrolled debts. Not all debts are eligible for enrollment. Not all clients complete our program for various reasons, including their ability to save sufficient funds. Estimates based on prior results, which will vary based on specific circumstances. We do not guarantee that your debts will be lowered by a specific amount or percentage or that you will be debt-free within a specific period of time. We do not assume consumer debt, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Not available in all states. Please contact a tax professional to discuss tax consequences of settlement. Please consult with a bankruptcy attorney for more information on bankruptcy. Depending on your state, we may be available to recommend a local tax professional and/or bankruptcy attorney. Read and understand all program materials prior to enrollment, including potential adverse impact on credit rating.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
