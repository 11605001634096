import { TextField } from "@mui/material";
import React, { useState } from "react";
import AutoComplete from "./AutoComplete";


const Step3 = ({ validationError, getDateOfBirth, dateOfBirth, getMapAddress, getStreetAddress, streetAddress }) => {
  const States = [
    { value: "Alabama" },
    { value: "Alaska" },
    { value: "Arizona" },
    { value: "Arkansas" },
    { value: "California" },
    { value: "Colorado" },
    { value: "Connecticut" },
    { value: "Delaware" },
    { value: "Florida" },
    { value: "Georgia" },
    { value: "Hawaii" },
    { value: "Idaho" },
    { value: "Illinois" },
    { value: "Indiana" },
    { value: "Iowa" },
    { value: "Kansas" },
    { value: "Kentucky" },
    { value: "Louisiana" },
    { value: "Maine" },
    { value: "Maryland" },
    { value: "Massachusetts" },
    { value: "Michigan" },
    { value: "Minnesota" },
    { value: "Mississippi" },
    { value: "Missouri" },
    { value: "Montana" },
    { value: "Nebraska" },
    { value: "Nevada" },
    { value: "New Hampshire" },
    { value: "New Jersey" },
    { value: "New Mexico" },
    { value: "New York" },
    { value: "North Carolina" },
    { value: "North Dakota" },
    { value: "Ohio" },
    { value: "Oklahoma" },
    { value: "Oregon" },
    { value: "Pennsylvania" },
    { value: "Rhode Island" },
    { value: "South Carolina" },
    { value: "South Dakota" },
    { value: "Tennessee" },
    { value: "Texas" },
    { value: "Utah" },
    { value: "Vermont" },
    { value: "Virginia" },
    { value: "Washington" },
    { value: "West Virginia" },
    { value: "Wisconsin" },
    { value: "Wyoming" }
  ];
  const [manuall, setManuallAddress] = useState(false);
  return (
    <>
      {/* <img src={line} className="w-75" alt="" /> */}
      <div className="container">
        <div className="row justify-content-lg-center mt-3">
          <div className="col-12">
            <h1 className="page-title mt-5">
              Personalize Your Savings!
            </h1>
            <p className="textccc font-18">
              In this last step, you can learn which plan you qualify for by filling in the information below.
            </p>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-lg-center">
              <div className="col-md-7">
                {!manuall ?
                  <AutoComplete getMapAddress={getMapAddress} />
                  :
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="input-container">
                        <input
                          onChange={getStreetAddress}
                          type="text"
                          name="address"
                          id="address"
                          className="form-control py-2 pdr-input rounded-4"
                          placeholder="Enter Your Name"
                          defaultValue=''
                        />
                        <label className='pdr-label' htmlFor="address">Address</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-container">
                        <input
                          onChange={getStreetAddress}
                          type="text"
                          name="city"
                          id="city"
                          className="form-control py-2 pdr-input rounded-4"
                          placeholder="Enter City"
                          defaultValue=''
                        />
                        <label className='pdr-label' htmlFor="city">City</label>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className='gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework track-dropdown-change_wrapper track-next-click_wrapper track-submit-click_wrapper' data-form-theme='legacy' data-form-index='0' id='gform_wrapper_281'>
                        <div className='gform-body gform_body'>
                          <div id='gform_page_281_1' className='gform_page page-one' data-js='page-field-id-1'>
                            <div className='gform_page_fields'>
                              <ul id='gform_fields_281' className='gform_fields top_label form_sublabel_below description_below'>
                                <li id="field_281_37" className="gfield gfield--type-select debt-dropdown gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible" data-js-reload="field_281_37">
                                  <div className='ginput_container ginput_container_select'>
                                    <select onChange={getStreetAddress} name='state' id='input_281_37' className='medium gfield_select' aria-required="true" aria-invalid="false" style={{ border: '1px solid' }}>
                                      <option value selected='selected' className='gf_placeholder'>State</option>
                                      {
                                        States.map((val, index) => {
                                          return (
                                            <option value={val.value} key={index}>{val.value}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="input-container">
                        <input
                          onChange={getStreetAddress}
                          type="text"
                          name="zip"
                          id="zip"
                          className="form-control py-2 pdr-input rounded-4"
                          placeholder="Enter ZipCode"
                          defaultValue=''
                        />
                        <label className='pdr-label' htmlFor="zip">Zip</label>
                      </div>
                    </div>
                  </div>
                }
                {!manuall ?
                  <p className="textccc font-12">
                    Can’t find your <span className="textblack">Your address?</span> <a onClick={() => setManuallAddress(true)} href="javascript:void(0)">Enter Manually</a>
                  </p>
                  :
                  <a onClick={() => setManuallAddress(false)} href="javascript:void(0)">Back to Address search</a>
                }
              </div>
              <div className="col-md-7 mt-3">
                <div class="input-container">
                  <input
                    onChange={(e) => getDateOfBirth(e)}
                    type="date"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    className="form-control py-2 pdr-input rounded-4"
                    placeholder="Date of Birth"
                    defaultValue=''
                  />
                  <label className='pdr-label' for="dateOfBirth">Date of Birth</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {validationError && (
        <div className="text-center mt-3" style={{ color: "red" }}>
          {validationError}
        </div>
      )}
    </>
  );
};

export default Step3;
